import { api, channelNames, listeners } from '@wix/promote-analytics-adapter'
import { BusinessLogger } from '@wix/thunderbolt-symbols'
import { getLoadedChannels } from './channels'
import { ReporterProps } from './channels/types'
import { LoadedScripts } from './tag-manager/types'

export const initChannels = (
	reporterProps: ReporterProps,
	loadedScripts: LoadedScripts,
	businessLogger: BusinessLogger
) => {
	const channels = getLoadedChannels(reporterProps, loadedScripts, businessLogger)
	api.init(channels)
	initGoogleReporterEC()
}

export const initListeners = (reporterProps: ReporterProps) => {
	api.addListener([listeners[channelNames.WIX_DEVELOPERS_ANALYTICS]], reporterProps)
}

export const trackEvent = (eventName: string, params = {}, options = {}) => {
	api.trackEvent(eventName, params, options)
}

function initGoogleReporterEC() {
	window && typeof window.ga === 'function' && window.ga('require', 'ec')
}
